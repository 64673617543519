<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title> สร้าง&ดาวน์โหลด Excel รถ </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form-group label="เลือกหัวข้อที่ต้องการแสดง">
          <b-form-checkbox
            v-for="option in columns"
            :key="option.key"
            v-model="selected"
            :value="option"
            name="car-option-excel"
            class="car-option-excel-item"
          >
            {{ option.name }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="เลือกฟิลเตอร์สถานะการค้นหา">
          <b-form-radio-group
            v-model="selectedOptionsUsable"
            :options="optionsUsable"
            name="car-filter-excel"
            class="car-filter-option"
          />
        </b-form-group>

        <b-button
          class="mt-50"
          size="sm"
          variant="primary"
          block
          :disabled="selected.length <= 0"
          @click="generateExcel"
          >ดาวน์โหลด Excel</b-button
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { utils, writeFile } from 'xlsx'
import { pick } from 'lodash'

export default {
  data() {
    return {
      columns: [
        { key: 'car_model', name: 'โมเดล' },
        { key: 'frame_number', name: 'หมายเลขเฟรม' },
        { key: 'car_size', name: 'ไซส์ (S,M,L)' },
        { key: 'car_name_en', name: 'ชื่อรถอังกฤษ' },
        { key: 'car_name_th', name: 'ชื่อรถไทย' },
        { key: 'image_id', name: 'รูป ID' },
        { key: 'selling_price', name: 'ราคา' },
        { key: 'cost_price', name: 'ต้นทุน' },
        { key: 'transfer_cost', name: 'ต้นทุนขนส่ง' },
        { key: 'register_cost', name: 'ต้นทุน หมายเลขทะเบียน' },
        { key: 'act_cost', name: 'ต้นทุน พรบ' },
      ],
      selected: [
        { key: 'car_model', name: 'โมเดล' },
        { key: 'selling_price', name: 'ราคา' },
        { key: 'cost_price', name: 'ต้นทุน' },
        { key: 'transfer_cost', name: 'ต้นทุนขนส่ง' },
      ],
      optionsUsable: [
        { value: 'open', text: 'เฉพาะสถานะเปิด' },
        { value: 'hide', text: 'เฉพาะสถานะปิด' },
        { value: 'all', text: 'ทั้งหมด' },
      ],
      selectedOptionsUsable: 'open',
    }
  },
  methods: {
    async generateExcel() {
      try {
        this.gOpenPageLoading()
        const resp = await this.$http.get('/api/finance-car-data').catch(() => null)

        if (resp?.code !== 200) {
          this.gDisplayToast('พบข้อผิดพลาด', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
          return
        }

        if (resp?.data?.length <= 0) {
          this.gDisplayToast('ไม่สามารถสร้าง Excel ได้', 'เนื่องจากข้อมูลรถไม่มีในระบบ', 'danger')
          return
        }

        const filterColumns = [...this.selected]

        if (this.selectedOptionsUsable === 'all') {
          filterColumns.push({
            key: 'usable',
            name: 'สถานะการค้นหา (0 ปิด, 1 เปิด)',
          })
        }

        const headers = filterColumns.map(col => col.name)
        const keys = filterColumns.map(col => col.key)

        const data = []

        resp.data.forEach(item => {
          if (this.selectedOptionsUsable !== 'all') {
            if (this.selectedOptionsUsable === 'open') {
              if (item.usable === true) {
                data.push(pick(item, keys))
              }
            } else if (item.usable === false) {
              data.push(pick(item, keys))
            }
          } else {
            data.push(pick(item, keys))
          }
        })

        const worksheetData = [
          headers, // แถวหัวข้อของคอลัมน์
          ...data.map(item =>
            keys.map(key => {
              if (key === 'usable') {
                return item[key] === true ? 1 : 0
              }

              if (key === 'car_size') {
                return item[key].toUpperCase()
              }

              return item[key]
            }),
          ),
        ]

        // สร้าง worksheet
        const worksheet = utils.aoa_to_sheet(worksheetData)

        // สร้าง workbook
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        const fileName = `honda-car-${this.selectedOptionsUsable}-${new Date().toISOString()}.xlsx`

        await writeFile(workbook, fileName)
      } catch (error) {
        this.gDisplayToast('พบข้อผิดพลาด', error?.message, 'danger')
      } finally {
        this.gClosePageLoading()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.car-option-excel-item {
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}
.car-filter-option {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
</style>
